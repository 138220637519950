export const useModalCleanup = async ( selectors ) => {

    await  onBeforeUnmount( async  () => {

        return
        let isActive =  false
        
        if( selectors && selectors.length ){

            for (const modalId of selectors) {
                // console.log('-----------------------  modalId', modalId)
                const modalElement =  document.getElementById( modalId ) ;
                // debugger
                // console.log('-----------------------   modalElement', modalElement)

                const modalInstance = bootstrap.Modal.getInstance(modalElement);
                // console.log('-----------------------  modalInstance', modalInstance)

                if (modalInstance) {
                    
                    // await  modalInstance.hide();
                   await  modalInstance.dispose();
                   isActive = true
                }
            }

            if( isActive ){

                //Check if 'modal-open' class exists on the body and remove it if present
                if (document.body.classList.contains('modal-open')) {
                    document.body.classList.remove('modal-open');
                }

                // Check if any elements with the class 'modal-backdrop' exist and remove them
                var modalBackdrops = document.querySelectorAll('.modal-backdrop');
                if (modalBackdrops.length > 0) {
                    modalBackdrops.forEach(function(modalBackdrop) {
                        modalBackdrop.remove();
                    });
                }
            }
        }
    });
}
  