<template>
    <div class="video_box">
		<Swiper 
		:modules="[SwiperPagination, SwiperAutoplay]" 
		:slidesPerView="1"
		:autoplay="{
			delay:26000,
			disableOnInteraction:false,
		}"
		:pagination="{
			el:'.home-sw-pgtn',
			clickable:true,
			type:'bullets',
		}"
		:loop="true"

		class="home-swiper"
>
			<SwiperSlide>
				<div class="for_desktop" v-if="!props.isMobile">
					<video @contextmenu.prevent="" controlsList="nodownload" class="videodiv" loop muted autoplay playsinline width="3840" height="1520">
						<source src="https://assets.friendlydiamonds.com/fdl/homepagebanner/black_friday_large.mp4" type="video/mp4">
					</video>
				</div>
				<div v-else class="for_mobile">
					<video @contextmenu.prevent="" controlsList="nodownload" class="videodiv" loop muted autoplay playsinline width="1380" height="1920">
						<source src="https://assets.friendlydiamonds.com/fdl/homepagebanner/black_friday_small.mp4" type="video/mp4">
					</video>
				</div>
				<NuxtLink :to="localePath({ name:'cyo-setting-list-ring'})"	>
					<div class="text_box">
							<p class="mb-1">Rocks That Rock!</p>
							<!-- <img src="~/assets/black_friday/banner_text.png" width="358" height="321" alt="50% off banner"/> -->
							<img src="~/assets/black_friday/cybermonday.png" width="371" height="284" alt="cyber monday sale"/>
							<p class="mb-3 mt-1">Up to 55% off on engagement <br>rings and fine jewelry</p>
							<div class="shop_now">
								Shop Now
							</div>
					</div>
				</NuxtLink>
			</SwiperSlide>
			<SwiperSlide>
				<div class="position-relative">
					<picture>
						<source media="(max-width: 767px)" width="750" height="1040" type="image/webp" srcset="~/assets/black_friday/banner_mob.webp">
						<source media="(max-width: 767px)" width="750" height="1040" type="image/jpeg" srcset="~/assets/black_friday/banner_mob.jpg">
						<source media="(min-width: 768px)" width="3840" height="1520" type="image/webp" srcset="~/assets/black_friday/banner.webp">
						<source media="(min-width: 768px)" width="3840" height="1520" type="image/jpeg" srcset="~/assets/black_friday/banner.jpg">

						<img loading="lazy" width="3840" height="1520" type="image/jpeg" src="~/assets/black_friday/banner.jpg" alt="Black friday banner">
					</picture>
					<NuxtLink :to="localePath({ name:'shop-all', query: { 'RefreshKey': 'all' } })"	>
						<div class="text_box text_box2">
							 <picture>
								<source media="(max-width: 767px)" width="320" height="284" type="image/jpeg" srcset="~/assets/black_friday/banner_offer_mob.png">
								<source media="(min-width: 768px)" width="320" height="284" type="image/jpeg" srcset="~/assets/black_friday/banner_offer.png">

								<img loading="lazy" width="320" height="284" type="image/jpeg" src="~/assets/black_friday/banner.jpg" alt="Black friday banner">
							 </picture>
							<p class="mb-3 mt-1">ON THE 2ND ITEM IN YOUR BAG! <span class="d-block d-md-inline"></span></p>
							<div class="shop_now">
								Shop Now
							</div>
						</div>
					</NuxtLink>
				</div>
			</SwiperSlide>
            <div class="home-sw-pgtn swiper-pagination"></div>
		</Swiper>
		<p class="terms-and-condition" @click="openBFModal">
			<span class="for_desktop">*terms & Conditions Applied</span>
			<span class="for_mobile">*T&C apply</span>
		</p>

		<div v-if="showModal">
            <Teleport  to="#teleports">
                <div class="modal fade " data-bs-backdrop="true" id="modalTall" tabindex="-1" aria-labelledby="modalTallLabel" aria-hidden="true" ref="modalRef">
                    <div class="modal-dialog modal-lg modal-width">
                        <div class="modal-content model_block p-0">    
                            <div class="modal-body p-0">
                                <div class="modal_body">
                                    <ModalTermsAndConditionsBF @hideModal="hideModal"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Teleport>
        </div>

    </div>
</template>
<script setup>
	const localePath = useLocalePath()
	const props = defineProps(['isMobile'])

	const showModal = ref(false);
    const modalRef = ref(null);

    const openBFModal =()=>{
        showModal.value = true;
        nextTick(() => {
            const modalElement = modalRef.value;
            if (modalElement) {
                const modalInstance = new bootstrap.Modal(modalElement);
                modalInstance.show();
                // modalInstance.on('hidden.bs.modal', function (e) {
                    
                //     console.log('model is hidden', )
                // })
            }
        });
    }

    const hideModal = () => {
        const modalElement = modalRef.value;
        if (modalElement) {
            const modalInstance = bootstrap.Modal.getInstance(modalElement);
            if (modalInstance) {
                modalInstance.hide();
            }
        }
        showModal.value = false;
    };
</script>
  
<style scoped>
	.videodiv{
		filter: brightness(0.85);
		display: block;
	}
	.video_box{
		position: relative;
	}
  	video{
		max-width: 100%;
		height: auto;
		/* clip-path: inset(0); */
		/* clip-path: inset(1px 1px) !important; */
	}
	.text_box{
		position: absolute;
		top: 50%;
		right: 0;
		text-align: left;
		color: #ffffff;
		transform: translate(-50%, -50%);
	}

	.text_box2{
		left: 50%;
		text-align: center;
	}

	.text_box h4 {
		font-size: 50px;
	}
	.text_box .shop_now{
		color: #ffffff;
		display: inline-block;
		border: 1px solid #ffff;
		font-weight: 600;
		margin: 0 auto;
		padding: 10px 25px;
		text-transform: uppercase;
		min-width: 200px;
		text-align: center;
		font-size: 14px;
		border-radius: 4px;
	}
	.text_box p {
		font-size: 18px;
		letter-spacing: 3px;
		text-transform: uppercase;
		color: #f5f5f5;
		text-shadow: 0px 0px 1.7px rgba(0, 0, 0, 0.25);
		margin-top: 0px;
	}

	.banner_btn {
		display: flex;
		justify-content: flex-start;
	}

	.banner_btn a {
		padding: 7px 20px;
		margin-right: 3%;
		color: #ffffff;
		border: 1px solid #ffffff;
		font-size: 14px;
		text-transform: uppercase;
	}

	:global(.home-swiper .home-sw-pgtn) {
		bottom: 20px;
	}

	:global(.home-swiper .swiper-pagination-bullet){
		background: #c4c4c4 !important;
		opacity: 1 !important;
		margin: 0 5px !important;
		position: relative;
		width: 60px;
		height: 5px;
		border-radius: 4px !important;
		border: 1px solid #c4c4c4;
	}	

	
	:global(.home-swiper .swiper-pagination-bullet::before) {
		content: '';
		position: absolute;
		top: 0;   
		left: 0;
		z-index: -1;
		height: 100%;
		width: 100%;
		background: #c4c4c4 !important;
		opacity: 1 !important;
	}

	:global(.home-swiper .swiper-pagination-bullet-active::before) {
		background: #fff !important;
		opacity: 1 !important;
		animation: countingBar 26s linear 1 forwards;
		z-index: 1;
	}

	@keyframes countingBar {
		0% {width: 0;}
		100% {width:100%;}
	}

	.terms-and-condition{
		color: #c4c4c4;
		letter-spacing: 1px;
		font-size: 10px;
		position: absolute;
		bottom: 10px;
		right: 15px;
		text-transform: uppercase;
		margin: 0;
		cursor: pointer;
		z-index: 1;
	}

	@media (max-width:1250px) {
		.text_box p {
			font-size: 16px;
			letter-spacing: 2px;
		}
		.text_box img {
			max-width: 80%;
		}

	}
	@media (max-width:992px) {
		
		.text_box img {
			max-width: 45%;
		}

		.text_box p{
			font-size: 14px;
		}

		
	}
	@media (max-width:767px) {
		
		.text_box img {
			max-width: 60%;
		}
	}
	
	@media (max-width:767px) {
		.video_box {
			position: relative;
			min-height: 60vh;
		}
		.text_box, .text_box2 {
			text-align: left;
			top: 63%;
			width: auto;
			transform: translate(-50%, -50%);
			left: 38%;
			right: auto;
		}
		.text_box h4 {
			font-size: 24px;
			text-align: center;
		}
		.text_box p {
			font-size: 12px;
			letter-spacing: 1px;
		}
		.banner_btn {
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
		.banner_btn a {
			margin: 5px;
			padding: 7px 20px;
			max-width: 300px;
			width: 100%;
		}
	}
	@media(min-width:992px){
		.modal-width.modal-lg{
			max-width: 960px;
		}
	}
</style>