<template>
    
    <div class="banner">

        <picture>
            
            <source media="(min-width: 992px)" width="2940" height="700" type="image/jpeg" srcset="~/assets/landing/fastship/black-friday/instock.jpg">
            <source media="(min-width: 992px)" width="2940" height="700" type="image/webp" srcset="~/assets/landing/fastship/black-friday/instock.webp">

            <!-- 
            <source media="(min-width: 768px) and (max-width: 991px)" width="1684" height="1120" type="image/jpeg" srcset="~/assets/landing/fastship/black-friday/instock_992.jpg">
            <source media="(min-width: 768px) and (max-width: 991px)" width="1684" height="1120" type="image/webp" srcset="~/assets/landing/fastship/black-friday/instock_992.webp"> -->


            <!-- <source media="(max-width: 767px)" width="686" height="1020" type="image/jpeg" srcset="~/assets/landing/fastship/black-friday/instock_mob.png"> -->
            <source media="(max-width: 992px)" width="687" height="960" type="image/webp" srcset="~/assets/landing/fastship/black-friday/instock_mob.webp">


            <img class="radious_eight" loading="lazy" width="2940" height="700" alt="Create Your Own Ring" src="~/assets/landing/fastship/black-friday/instock.jpg">

        </picture>

        <div class="ship_upper">

            <h4 class="font-active mb-2">Love, Delivered Faster!</h4>

            <p class="mb-3">Running out of time? Find the perfect <br> gift from our selection with guaranteed 7-day shipment! 
            </p>

            <div class="ship_flex">

                <NuxtLink class="anker radious_four" :to="localePath({ name: 'instock_ring' })">Ready To Ship
                    Rings</NuxtLink>
                <NuxtLink class="anker radious_four" :to="localePath({ name:'shop-all',  query:{'Shipdate': '3_days'} })">Ready To Ship Fine
                    Jewelry</NuxtLink>

            </div>

        </div>

    </div>

</template>

<script setup>

    const localePath = useLocalePath();

</script>


<style scoped>
img {
    width: 100%;
}

.banner {
    position: relative;
}

.ship_upper {
    position: absolute;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%);
    width: 45%;
    text-align: left;
}

.ship_upper h4 {
    font-size: 32px;
    color: #ffffff;
}

.ship_upper p {
    padding: 10px 0;
    /* color: #353535; */
    color: #ffffff;
}

.anker {
    display: inline-block;
    font-size: 14px;
    /* color: #000; */
    color: #ffffff;
    /* border: 1px solid #000; */
    border: 1px solid #ffffff;
    text-transform: uppercase;
    font-weight: 600;
    padding: 10px 15px;
}

.ship_flex {
    display: flex;
    gap: 15px;
}

@media (max-width: 1300px) {
    .ship_upper {
        width: 50%;
    }

    .ship_upper h4 {
        font-size: 24px;
    }

    .ship_upper p {
        font-size: 14px;
    }

    .anker {
        font-size: 14px;
        padding: 10px 15px;
    }
}

@media(max-width: 991px) {
    .ship_upper {
        position: absolute;
        top: 74%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 45%;
        text-align: center;
    }

    .ship_upper p br {
        display: none;
    }

    .ship_flex {
        display: flex;
        flex-direction: column;
        gap: 0;
    }

    .anker {
        margin-bottom: 12px;
        text-align: center;
    }
}

@media (max-width: 767px) {
    .ship_upper {
        width: 90%;
        text-align: center;
    }

    .ship_upper h4 {
        font-size: 24px;
    }

    .ship_upper p {
        font-size: 14px;
    }

    .ship_flex {
        width: 100%;
        align-items: center;
    }

    .anker {
        width: 80%;
        text-align: center;
        font-size: 14px;
        padding: 12px 14px;
    }

}
</style>
