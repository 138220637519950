<template>

    <div class="offer_category">
        <div class="row black_friday_block">
            <div class="col-12 col-md-6 col-xl-3 eng_block">
                <div class="img_block">
                    <picture>
                        <!-- <source width="1380" height="1360" type="image/webp" srcset="~/assets/home/black_friday/early_access.webp">
                        <source width="1380" height="1360" type="image/jpeg" srcset="~/assets/home/black_friday/early_access.jpg"> -->
                        <source width="1380" height="1360" type="image/webp" srcset="~/assets/home/black_friday/cyber_monday_offer.webp">
                        <source width="1380" height="1360" type="image/jpeg" srcset="~/assets/home/black_friday/cyber_monday_offer.jpg">
                        <img class="radious_eight" width="1380" loading="lazy" height="1360" src="~/assets/home/black_friday/cyber_monday_offer.jpg" type="image/jpeg" alt="early banner"> 
                    </picture>
                    <p class="terms-text" @click="openBFModal">Terms & Conditions</p>
                </div>
            </div>
            <div class="col-12 col-md-6 col-xl-3 eng_blocks">
                <NuxtLink :to="localePath({ name:'cyo-setting-list-ring'})">
                    <div class="img_block">
                        <picture>
                            <source width="1380" height="1360" type="image/webp" srcset="~/assets/home/black_friday/three_ring.webp">
                            <source width="1380" height="1360" type="image/jpeg" srcset="~/assets/home/black_friday/three_ring.jpg">
                            <img class="radious_eight" width="1380" loading="lazy" height="1360" src="~/assets/home/black_friday/three_ring.jpg" type="image/jpeg" alt="early banner"> 
                        </picture>

                        <div class="three_rings">
                            <div class="text_block">
                                <p class="mb-0 font-active">Create Your Own</p>
                                <span>Engagement Rings</span>
                            </div>
                            <div class="shop_now">
                                Shop Now
                            </div>
        
                        </div>
                    </div>
                </NuxtLink>
            </div>
            <div class="col-12 col-md-6 col-xl-3 eng_blocks">
                <NuxtLink :to="localePath({ name: 'preset-listing', 'RefreshKey': 'all'})">
                    <div class="img_block">
                        <picture>
                            <source width="1380" height="1360" type="image/webp" srcset="~/assets/home/black_friday/two_ring.webp">
                            <source width="1380" height="1360" type="image/jpeg" srcset="~/assets/home/black_friday/two_ring.jpg">
                            <img class="radious_eight" width="1380" loading="lazy" height="1360" src="~/assets/home/black_friday/two_ring.jpg" type="image/jpeg" alt="early banner"> 
                        </picture>
                        <div class="three_rings">
                            <div class="text_block">
                                <p class="mb-0 font-active">Pre-Designed</p>
                                <span>Engagement Rings</span>
                            </div>
                                <div class="shop_now">   
                                    Shop Now
                                </div>
                        </div>
                    </div>
                </NuxtLink>
            </div>
            <div class="col-12 col-md-6 col-xl-3 eng_blocks">
                <NuxtLink :to="localePath({ name:'shop-all', query: { 'RefreshKey': 'all' } })">
                    <div class="img_block">
                        <picture>
                            <source width="1380" height="1360" type="image/webp" srcset="~/assets/home/black_friday/jewel_set.webp">
                            <source width="1380" height="1360" type="image/jpeg" srcset="~/assets/home/black_friday/jewel_set.jpg">
                            <img class="radious_eight" width="1380" loading="lazy" height="1360" src="~/assets/home/black_friday/jewel_set.jpg" type="image/jpeg" alt="early banner"> 
                        </picture>
                        <div class="three_rings">
                            <div class="text_block">
                                <p class="mb-0 font-active">Fine Jewelry</p>
                                    <span>All Categories</span>
                            </div>
                                <div class="shop_now">
                                    Shop Now
                                </div>
                        </div>
                    </div>  
                </NuxtLink>  
            </div>
        </div>
        <div v-if="showModal">
        <Teleport  to="#teleports">
            <div class="modal fade" data-bs-backdrop="true" id="modalTall" tabindex="-1" aria-labelledby="modalTallLabel" aria-hidden="true" ref="modalRef">
                <div class="modal-dialog modal-lg modal-width">
                    <div class="modal-content model_block p-0">    
                        <div class="modal-body p-0">
                            <div class="modal_body">
                                <ModalTermsAndConditionsBF @hideModal="hideModal"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Teleport>
    </div>
    </div>
    

</template>

<script setup>
 const localePath = useLocalePath()
 const showModal = ref(false);
 const modalRef = ref(null);
 const openBFModal =()=>{
        showModal.value = true;
        nextTick(() => {
            const modalElement = modalRef.value;
            if (modalElement) {
                const modalInstance = new bootstrap.Modal(modalElement);
                modalInstance.show();
            }
        });
    }

    const hideModal = () => {
        const modalElement = modalRef.value;
        if (modalElement) {
            const modalInstance = bootstrap.Modal.getInstance(modalElement);
            if (modalInstance) {
                modalInstance.hide();
            }
        }
        showModal.value = false;
    };
</script>

<style scoped>
.offer_category {
    padding: 2% 4%;
}

.text_block p{
    font-size: 24px;
    color: #21324d;
}
.text_block span{
    font-size: 14px;
    letter-spacing: 1.5px;
    color: #ffff;
    text-transform: uppercase;
}
.text_block{
    margin-bottom: 12px;
}
.img_block{
    position: relative;
}
.three_rings {
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 7% 10%;
}
.three_rings .shop_now {
    border: 1px solid #ffffff;
    border-radius: 4px;
    padding: 8px 10px;
    cursor: pointer;
}
.three_rings .shop_now{
    font-size: 14px;
    color: #ffff;
    font-weight: 500;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
}
.terms-text{
    position: absolute;
    top: 10px;
    left: 10px;
    color: #6a6161;
    text-decoration: underline;
    font-size: 12px;
    cursor: pointer;
}



@media (max-width:1500px){
    .eng_blocks , .eng_block{
        margin: 20px 0 0 0;
    }
    .text_block p {
        font-size: 18px;
    }
    .three_rings .shop_now{
        padding: 5px 10px;
    }
}
@media (max-width: 1200px){
    
    .three_rings .shop_now{
        padding: 8px 10px;
    }
    .text_block span{
        font-size: 16px;
    }
    .three_rings .shop_now{
        font-size: 16px;
    }
    .text_block p{
        font-size: 28px;
    }   

}
@media (max-width: 767px){
    .offer_category {
        padding: 0 15px;
    }
}

@media(min-width:992px){
    .modal-width.modal-lg{
        max-width: 960px;
    }

    .terms-text:hover{
        color: #eaeaea;
    }
}


</style>