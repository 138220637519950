<template>
    <section class="ur_own py-4">
        <div class="main_row">
            <div class="cyo_box new_left_row">
                <NuxtLink :to="localePath({ name:'cyo-setting-list-ring'})" class="radious_eight">
                        <picture>
                            <source width="1681" height="1500" type="image/webp" srcset="~/assets/home/cyo/india/black_friday/cyor.webp">
                            <img loading="lazy" width="1681" height="1500" type="image/jpeg" src="~/assets/home/cyo/india/black_friday/cyor.jpg" alt="Create Your Own Engagement Rings Mobile Picture">
                        </picture>
                        <span>Engagement Rings</span>
                    </NuxtLink>
            </div>
         
            <div class="cyo_block">
                
                <div class="cyo_box cyo_box_mod_one new_mod">
                    <NuxtLink :to="localePath({ name: 'cyo-setting-list-pendant' })" class="radious_eight">
                        <picture>
                            <source width="1161" height="730" type="image/webp" srcset="~/assets/home/cyo/india/black_friday/cyop.webp">
                            <source width="1161" height="730" type="image/jpeg" srcset="~/assets/home/cyo/india/black_friday/cyop.jpg"> 
                            <source media="(max-width: 767px)" width="332" height="381" type="image/webp" srcset="~/assets/home/cyo/india/black_friday/cyop_mob.webp">
                            <source media="(max-width: 767px)" width="332" height="381" type="image/jpeg" srcset="~/assets/home/cyo/india/black_friday/cyop_mob.jpg"> 
                            <img loading="lazy" width="1161" height="730" type="image/jpeg" src="~/assets/home/cyo/india/black_friday/cyop.jpg" alt="Create Your Own Pendants Picture">
                        </picture>
                        <span>Pendants</span>
                    </NuxtLink>
                </div>

                <div class="cyo_box cyo_box_mod_two">
                    <NuxtLink :to="localePath({ name: 'cyo-setting-list-earring' })" class="radious_eight">
                        <picture>
                            <source width="1161" height="730" type="image/webp" srcset="~/assets/home/cyo/india/black_friday/cyoe.webp">
                            <source width="1161" height="730" type="image/jpeg" srcset="~/assets/home/cyo/india/black_friday/cyoe.jpg">
                            <source media="(max-width: 767px)" width="332" height="381" type="image/webp" srcset="~/assets/home/cyo/india/black_friday/cyoe_mob.webp">
                            <source media="(max-width: 767px)" width="332" height="381" type="image/jpeg" srcset="~/assets/home/cyo/india/black_friday/cyoe_mob.jpg">
                            <img loading="lazy" width="1161" height="730" type="image/jpeg" src="~/assets/home/cyo/india/black_friday/cyoe.jpg" alt="Create Your Own Earrings Picture">
                        </picture>
                        <span>Earrings</span>
                    </NuxtLink>    
                </div>

            </div>
                

        </div>
    </section>
</template>

<script setup>
    const localePath = useLocalePath()
    const props = defineProps(['locale'])
</script>

<style scoped>

.main_row{
    display: flex;
    justify-content: center;
}
img{
    /* image-rendering: pixelated; */
}
.cyo_text {
    background: #405955;
    text-align: center;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 6% 15%;
    margin-bottom: 7px;
}
.cyo_text h1 {
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: 10px;
}
.cyo_text p {
    font-size: 16px;
    font-weight: 400;
    padding-top: 3%;
    color: #eaeaea;
}
.cyo_box {
    position: relative;
    margin-top: 5px;
    /* flex: 1; */
}
.cyo_box:first-child {
    margin-right: 20px;
}
.cyo_box_mod{
    display: none;
}
.cyo_box:last-child {
    margin-top: 0px;
}
.cyo_box_mod_one:first-child{
    margin-right: 0;
}
.cyo_block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.cyo_box a {
    display: block;
    color: #000;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
.cyo_box a img:hover{
    transform: scale(1.1);
    transition: 0.8s;
}
.cyo_box a img{
    transition: 0.8s;
}
.cyo_box span {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    display: block;
    font-size: 18px;
    font-weight: 400;
    width: calc(100% + 2px);
    text-align: center;
    background: rgba(255, 255, 255, 0.68);
    color: #000;
    padding: 7px;
    text-transform: uppercase;
    letter-spacing: 5px;
}
.cyo_box:hover span {
    color: #000;
}
@media(max-width: 1150px){
    .cyo_box span {
        font-size: 16px;
        letter-spacing: 5px;
    }
    .cyo_text h1 {
        font-size: 24px;
        letter-spacing: 3px;
    }
    .cyo_text  br{
        display: none;
    }
    .cyo_text{
        padding: 6%;
    }
    .cyo_box:last-child {
        margin-top: 12px;
    }
.cyo_box_mod_one:first-child{
    margin-right: 0;
}
}
@media(max-width: 992px){
    .cyo_box span{
        font-size: 14px;
        letter-spacing: 2px;
    }
    .cyo_text{
        padding: 0 5%;
    }
    .cyo_text p{
        font-size: 14px;
        padding-top: 0;
    }
    .cyo_text h1{
        font-size: 22px;
    }
}
@media(max-width: 767px){
    .cyo_box:last-child {
        margin-top: 5px;
    }

    .cyo_box.cyo_box_mod_two {
        padding-left: 10px;
        width: 50%;
    }
    .cyo_block {
        display: flex;
        flex-direction: row;
    }
    .cyo_row {
        display: flex;
        flex-direction: row;
        }

    .cyo_box.cyo_box_mod_one {
        width: 50%;
        padding-right: 10px;
    }
    .cyo_box {
        margin-bottom: 20px;
    }
    
    .main_row{
        flex-wrap: wrap;
    }
    .left_row{
        flex-wrap: wrap;
        flex: auto;
    }
    .row.cyo_block.mb-3{
        display: flex;
        flex-direction: row;
    }
    .new_left_row{
        width: 100%;
    }
    .cyo_text {
        padding: 7% 10%;
        /* max-width: 319px; */
        margin: 0 auto 4% auto;
    }
    .cyo_box:last-child {
        margin-left: 0;
    }
    .cyo_box:first-child {
        margin-right: 0;
    }
    .cyo_box span {
        color: #000;
        padding: 3px;
        font-size: 12px;
    }
    .cyo_text h1{
        font-size: 20px;
    }
    .cyo_text p{
        font-size: 14px;
        padding-top: 5%;
    }
    .cyo_box.for_mobile {
        width: 100%;
    }
    .cyo_box {
        width: 100%;
    }
}
@media(max-width: 512px){
    .main_row {
        flex-wrap: wrap; 
    }
    .cyo_box_mod{
        display: block;
    }
}
</style>
